import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Container from '@/components/Layout/Container'
import Select from '@/components/Select'
import MainHeading from '@/components/MainHeading'
import Seo from '@/components/Seo'
import LoadMore from '@/components/LoadMore'
import 'react-dropdown-now/style.css'
import moment from 'moment'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { clearQuerySrings, getQurySring } from '@/functions'
import { seotype } from '@/types.d'
interface Posts {
  id: string
  title?: string
  uri: string
  content?: string
  date: string
  terms?: {
    nodes: {
      id: string
      name: string
    }
  }
  featuredImage: any
  firstPostImage: any
}
interface PostCat {
  name: string
  slug: string
  id: string
  uri: string
}
interface PostsData {
  data: {
    form: any
    page: {
      title: string
      featuredImage: any
      language: {
        slug: string
      }
      uri: string
      seo: seotype
      translations: []
    }
    firstPost: {
      nodes: Array<Posts>
    }
    allWpGwPost: {
      nodes: Array<Posts>
    }
    categories: {
      nodes: Array<PostCat>
    }
    mainMenu: any
    langData: any
    wp: any
  }
}

const PostsTemplate = ({ data }: PostsData) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(page.language.slug)
  }, [])

  const { page } = data
  const { form } = data
  const { allWpGwPost } = data
  const { categories } = data

  const s = getQurySring()

  const [year, setYear] = useState('')
  const [type, setType] = useState(s || '')

  const [yearFilters, setYearFilters] = useState([])

  clearQuerySrings()

  const setYearFilter = (currentYear: string) => {
    setYear(currentYear)
  }

  const setTypeFilter = (currentType: string) => {
    setType(currentType)
  }
  const clearFilters = () => {
    setType('')
    setYear('')
  }

  const filterDate = (item: any) => {
    if (moment(item.date).format('YYYY') === year || year === '') {
      return true
    }
  }

  const filterTerms = (item: any) => {
    if (item?.terms?.nodes.some((t: any) => t?.name === type || type === '')) {
      return true
    } else if ((item?.terms?.nodes.length === 0 && type == '') || (item?.terms === null && type == '')) {
      return true
    }
  }

  const filterResults = () => {
    const filterYear: any = allWpGwPost?.nodes.filter(filterDate)
    const FilterTerm: any = filterYear?.filter(filterTerms)
    return FilterTerm
  }

  const results = filterResults()

  const { mainMenu } = data
  const { wp } = data

  const langData = { languages: wp.languages, currentLang: page.language.slug, translations: page.translations }
  const testkey = new Date()
  const loadmoreKey = testkey.getTime()

  const currentYear = moment().year()

  useEffect(() => {
    const years: any = []

    for (let i = 0; i < 7; i++) {
      years.push(currentYear - i)
    }

    setYearFilters(years)
  }, [])

  return (
    <Layout featuredImage={page.featuredImage} lang={langData} nav={mainMenu} form={form}>
      {page.seo && (
        <Seo
          title={page?.seo?.title}
          description={page?.seo?.metaDesc}
          keywords={page?.seo?.metaKeyword}
          pathname={page?.uri}
          lang={page?.language?.slug}
          image={page?.featuredImage?.node?.localFile?.url}
          twitterTitle={page?.seo?.twitterTitle}
          twitterDescription={page?.seo?.twitterDescription}
          opengraphTitle={page?.seo?.opengraphTitle}
          opengraphDescription={page?.seo?.opengraphDescription}
          opengraphImage={page?.seo?.opengraphImage?.localFile?.url}
          twitterImage={page?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <Container className="my-10">
        <div className="flex mb-20 lg:flex-row flex-col">
          <MainHeading>{page.title}</MainHeading>
          <div className="lg:ml-auto md:flex mt-10 lg:mt-0">
            <Select className="mr-6" selectName={t('Aihe')} activeSelect={type}>
              <button
                className="hover:bg-fadeblackmin hover:text-white focus:bg-fadeblackmin focus:text-white py-2"
                onClick={() => setTypeFilter('')}
              >
                <Trans>Kaikki</Trans>
              </button>
              {categories.nodes.map(cat => {
                return (
                  <button
                    className="hover:bg-fadeblackmin hover:text-white focus:bg-fadeblackmin focus:text-white py-2"
                    onClick={() => setTypeFilter(`${cat.name ?? ''}`)}
                    key={cat.id}
                  >
                    {cat.name}
                  </button>
                )
              })}
            </Select>
            <Select className="mt-4 md:mt-0 mr-6" selectName={t('Vuosi')} activeSelect={year}>
              <button
                className="hover:bg-fadeblackmin hover:text-white focus:bg-fadeblackmin focus:text-white py-2"
                onClick={() => setYearFilter('')}
              >
                <Trans>Kaikki</Trans>
              </button>
              {yearFilters.map((item: any) => {
                  return (
                    <button
                      key={item}
                      className="hover:bg-fadeblackmin hover:text-white focus:bg-black hover:bg-black focus:text-white py-2"
                      onClick={() => setYearFilter(`${item ?? ''}`)}
                    >
                      {item}
                    </button>
                  )
                })}
            </Select>
            <div>
              {(year || type) && (
                <button
                  className="roundedButton hover:text-white hover:bg-black hover:border-black focus:text-white focus:bg-black focus:border-black mt-4 md:mt-0"
                  onClick={() => clearFilters()}
                >
                  <Trans>Tyhjennä</Trans>
                </button>
              )}
            </div>
          </div>
        </div>

        {results && (
          <LoadMore
            readMoreString={t('Lue lisää')}
            reduceByone={true}
            load={5}
            key={loadmoreKey}
            classNameButton="flex items-center justify-center my-20"
            className="mt-4 sm:mt-6 grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6"
            postType="posts"
            posts={results}
            buttonText={t('Katso lisää')}
          />
        )}
      </Container>
    </Layout>
  )
}

export const PostsPageQuery = graphql`
  query PostsPageById(
    $id: String
    $postlang: [String]
    $menu: [WpMenuLocationEnum]
    $translang: String
    $formNum: Int
  ) {
    # selecting the current page by id
    page: wpPage(id: { eq: $id }) {
      id
      title
      ...featuredHeroImage
      ...langdata
      ...seo
    }
    categories: allWpGwPostCustomTaxonomySingle {
      nodes {
        name
        slug
        id
        uri
      }
    }
    allWpGwPost(sort: { fields: date, order: DESC }, filter: { language: { slug: { in: $postlang } } }) {
      ...allPosts
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $formNum }) {
      ...GravityFormFields
    }
  }
`

export default PostsTemplate
