import React, { useEffect, useState, useRef } from 'react'
import Arrow from '@/assets/Arrow'

interface Data {
  children?: React.ReactNode
  className?: string
  selectName?: string
  activeSelect?: string
}

const Select = ({ children, className = '', selectName, activeSelect }: Data) => {
  const [show, setShow] = useState(false)

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  const change = () => {
    setShow(show == true ? false : true)
  }

  const active = activeSelect == '' ? selectName : activeSelect

  return (
    <div className={`relative inline-block ${className}`} ref={wrapperRef}>
      <button
        onClick={() => change()}
        className={` ${
          show == false ? null : 'text-white bg-black border-black'
        } tracking-full px-6 pt-2 pb-1 border-solid border-3 rounded-3xl uppercase text-center font-bold flex items-center hover:text-white hover:bg-black hover:border-black focus:text-white focus:bg-black focus:border-black`}
      >
        <span className="ml-8">{active}</span>{' '}
        <span className={`arrow-icon transition-transform ml-5 ${show == false ? null : 'rotate-select'}`}>
          <Arrow />
        </span>
      </button>
      {show && (
        <div className="absolute shadow-xl rounded-lg py-5 px-2 z-10 bg-latte flex-col flex text-xl w-full">
          {children}
        </div>
      )}
    </div>
  )
}
export default Select
